import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Card } from '../../utils/models/cards/card'
import { isStringIn, startWith, cleanName, removeStringFromStartingStringToEndingString } from '../../utils/string/string.util'
import { generateFilterStringFromFilterArrays, specialFilters, TYPE_REMOVED } from '../../utils/filter/filter.util'
import { FilterEmission } from '../../utils/models/emissions/filterEmission';
import { ActivatedRoute } from '@angular/router';
import { CardsService } from 'src/app/services/cards/cards.service';
import { CacheService } from 'src/app/services/cache/cache.service';
import { getArrayOfUniqueParametersFromArrayOfObjects } from 'src/app/utils/array/array.utils';
import { removedFromSelectionCards, removedFromSelectionCardTypes } from 'src/app/utils/card.util';
import { AutocompletedWithOperatorSelectorComponent } from '../selectors/autocompleted-with-operator-selector/autocompleted-with-operator-selector.component';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {
  cards: Card[] = [];
  displayedCards: Card[] = [];
  filter: string = '';
  cleanName = cleanName;
  DEFAULT_RESEARCH = 'Royal Magical Library';

  displayCardImage = false;
  displayedCardImageUrl = '';

    // Display Hide filters section
    displayOrHideFilters: boolean = false;
    displayButtonTextOptions: string[] = ['Display filters', 'Hide filters'];
    displayButtonText: string = 'Display filters';

    changeFiltersDisplay(): void {
      this.displayOrHideFilters = !this.displayOrHideFilters;
      this.displayButtonText = this.displayButtonTextOptions[(this.displayButtonTextOptions.indexOf(this.displayButtonText) + 1) % this.displayButtonTextOptions.length];
    }

  //Filter data
  filterInfo = specialFilters;

  @ViewChildren('selectors') selectors: QueryList<AutocompletedWithOperatorSelectorComponent>;

  constructor(private cardService: CardsService, private cacheService: CacheService, private activatedRoute: ActivatedRoute) {
  }

  async ngOnInit() {
    const importedFromOtherComponentFilters = window.history.state['filters'];

    if (importedFromOtherComponentFilters) {
      this.filter = generateFilterStringFromFilterArrays(importedFromOtherComponentFilters);
    } else if (this.cacheService.hasKey('filter')) {
      this.filter = this.cacheService.get('filter');
    }

    this.cards = await this.cardService.getAllCards();

    for (const removedCardType of removedFromSelectionCardTypes) {
      this.cards = this.cards.filter(card => card.type !== removedCardType)
    }

    for (const removedFromSelectionCard of removedFromSelectionCards) {
      this.cards = this.cards.filter(card => card.name !== removedFromSelectionCard)
    }


    this.applyFilter();

    this.fillFiltersInfoWithSelection();
  }

  getInput(key: KeyboardEvent) {
    if (key.key === 'Enter') {
      this.applyFilter();
    }
  }

  applyFilter() {
    this.displayedCards = this.cards;
    this.cacheService.put('filter', this.filter);
    const filters = this.filter.split(' & ');

    for (const actualFilter of filters) {
      let specialFilter: boolean = false;
      for (const filterParameter of specialFilters) {
        if (startWith(actualFilter, filterParameter.keychar)) {
          filterParameter.action(this, this.displayedCards, actualFilter);
          specialFilter = true;
        }
      }

      if (!specialFilter) {
        if (actualFilter.trim().length === 0) {
          if (filters.length === 1) {
            this.displayedCards = [];
          } else {
            return;
          }
        } else {
          this.displayedCards = this.displayedCards.filter(card => isStringIn(card.name, actualFilter));
        }
      }
    }

    for (const removedCardType of removedFromSelectionCardTypes) {
      this.displayedCards = this.displayedCards.filter(card => card.type !== removedCardType)
    }

    for (const removedFromSelectionCard of removedFromSelectionCards) {
      this.displayedCards = this.displayedCards.filter(card => card.name !== removedFromSelectionCard)
    }
  }

  addFilter(addedFilter: FilterEmission): void {
    if (addedFilter.isUnique) {

      if (isStringIn(this.filter, addedFilter.char)) {
        this.filter = removeStringFromStartingStringToEndingString(this.filter, addedFilter.char, '&');
      }
    }

    this.filter = this.filter.trim() + (this.filter.trim() !== '' ? ' & ' + addedFilter.emission : addedFilter.emission);

    this.applyFilter();
  }

  cleanFilters(emission: string) {
    this.filter = '';
    this.applyFilter();
    for (const selector of this.selectors.toArray()) {
      selector.clean()
    }
  }

  // This function fill the option selector with its content with all potential options, fetched from all the cards
  private fillFiltersInfoWithSelection(): void {
    for (const id of this.filterInfo.map(filter => filter.id)) {
      const filterTargeted = this.filterInfo.filter(filter => filter.id === id)[0];
      if (filterTargeted.type === String) {
        //TODO Setcode and keyword arn't implemented YET
        filterTargeted.selection = getArrayOfUniqueParametersFromArrayOfObjects(this.cards, id).sort().filter(value => !TYPE_REMOVED.includes(value));
      } else if (filterTargeted.type === Number) {
        filterTargeted.selection = getArrayOfUniqueParametersFromArrayOfObjects(this.cards, id).sort((a, b) => parseInt(a) - parseInt(b)).filter(value => !TYPE_REMOVED.includes(value));
      } else if (filterTargeted.type === Card){
        filterTargeted.selection = getArrayOfUniqueParametersFromArrayOfObjects(this.cards, id)
          .filter(value => !TYPE_REMOVED.includes(value))
          .map(value => {
            if(value.length > 0) return value.map(set => set.set_rarity);
            if(value.ban_tcg) return value.ban_tcg[0].toUpperCase() + value.ban_tcg.substr(1);
          }).reduce((accumulator, value) => accumulator.concat(value), []).filter((elem, index, self) => index === self.indexOf(elem))
          .sort();
      }
    }
  }

  displayCardByMousePosition(imageUrl: string, mousePosition: { mouseX: number, mouseY: number }) {

    this.displayedCardImageUrl = imageUrl;
    this.displayCardImage = true;
    document.getElementById("fixed-card-image").style.width= window.innerWidth/2 + "px"; // Image will be half the screen's width (capped at 400px in CSS)
    let imageX = mousePosition.mouseX; 
    if (imageX > window.innerWidth/2) {  // If mouse goes over half the screen in X, image doesn't follow to stay on screen
      imageX = window.innerWidth/2;
    }
    document.getElementById("fixed-card-image").style.left= imageX + "px";

    let imageY = 0;
    if (window.innerWidth > 800) {
      imageY = mousePosition.mouseY - (1.3 * 400); // On big screens image is capped at 400px width, 1.34 determines its height
    } else {
      imageY = mousePosition.mouseY - (1.3 * window.innerWidth/2); // On smaller screens we need to determine the card's width to then calculate its height
    }
    
    if (mousePosition.mouseY > window.innerHeight/2) {
      document.getElementById("fixed-card-image").style.top = imageY + "px"; // If mouse goes over half the screen in Y, we place the image above the mouse
    } else {
      document.getElementById("fixed-card-image").style.top= mousePosition.mouseY + "px"; // Above half the screen in Y, image's top is at mouse position
    }
  }

  hideCardImage() {

    this.displayedCardImageUrl = '';
    this.displayCardImage = false;
  }
}
