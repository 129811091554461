import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LibraryComponent } from './components/library/library.component';
import { CardPannelComponent } from './components/card-pannel/card-pannel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RuleDescriptionComponent } from './components/rule-description/rule-description.component';
import { AutocompletedSelectorComponent } from './components/selectors/autocompleted-selector/autocompleted-selector.component';
import { CleanButtonComponent } from './components/clean-button/clean-button.component';
import { TopScrollerComponent } from './components/top-scroller/top-scroller.component';
import { HeaderComponent } from './components/header/header.component';
import { AutocompletedWithOperatorSelectorComponent } from './components/selectors/autocompleted-with-operator-selector/autocompleted-with-operator-selector.component';
import { LoginComponentComponent } from './components/login-component/login-component.component';

@NgModule({
  declarations: [
    AppComponent,
    LibraryComponent,
    CardPannelComponent,
    PageNotFoundComponent,
    RuleDescriptionComponent,
    AutocompletedSelectorComponent,
    CleanButtonComponent,
    TopScrollerComponent,
    HeaderComponent,
    AutocompletedWithOperatorSelectorComponent,
    LoginComponentComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
