<app-header></app-header>
<section>
    <div class="legend">
        <p>Use search bar to find cards:</p>
        <div class="filterslist">
            <ul>
                <li>x: Find by text</li>
                <li>c: Find by card type</li>
                <li>r: Find by Type</li>
                <li>att: Find by monster ATTRIBUTE</li>
                <li>l: Find by monster Level/Rank</li>
            </ul>
            <ul>
                <li>sca: Find by Pendulum Scale</li>
                <li>set: Find by set code</li>
                <li>a: Find by archetype</li>
                <li>rar: Find by card rarity</li>
                <li>ban: Find by TCG banlist status</li>
            </ul>
        </div>
        <p>You may stack filters by separating each filter with '&' and spaces.</p>
    </div>

    <div class="search">
        <input [(ngModel)]="this.filter" type="text" (keydown)="getInput($event)" class="search__bar" (filterEmission)="addFilter($event)" />
        <button (click)="applyFilter()">Search</button>
        <app-clean-button text="Clear" (clearEmission)="cleanFilters($event)"></app-clean-button>

        <button (click)="changeFiltersDisplay()" class="display-button">{{this.displayButtonText}}</button>
        <div class="search__filters" *ngIf="this.displayOrHideFilters">
            <div *ngFor="let filter of this.filterInfo, let i = index">
                <app-autocompleted-with-operator-selector #selectors [isUnique]="filter.isUnique" [selector]="filter.selector" labelName="{{filter.label}}" keychar="{{filter.keychar}}" [selection]="filter.selection" [operators]="filter.operators" (filterEmission)="addFilter($event)">
                </app-autocompleted-with-operator-selector>
            </div>
        </div>


        <p> {{this.displayedCards.length}} card{{this.displayedCards.length > 1 ? 's' : '' }} matching your research</p>
    </div>
</section>
<div class="card-list">
    <a *ngFor="let card of this.displayedCards" class="card-list__card-block {{card.type}}" routerLink="card/{{this.cleanName(card.name)}}" (mouseenter)="displayCardByMousePosition(this.card.card_images[0].image_url, {mouseX: $event.x, mouseY: $event.y})"
        (mouseleave)="hideCardImage()">
    {{card.name}}
  </a>
</div>

<img class="fixed-card-image" id="fixed-card-image" [src]="this.displayedCardImageUrl" />

<router-outlet></router-outlet>